import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const FirePipePage = () => {
  return (
    <Layout hero={<HeroImage title="OrrFire FirePipe" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center mt-4">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  ORRFIRE® Pipe is a leading product specifically designed for pressure reticulation in commercial and industrial fire protection applications. 
                  Manufacturing firepipe requires the experience of a specialised steel supplier that will never compromise 
                  the rigid safety standards that must apply to this product. 
                  Our fire pipe products cover a diverse range of wall thicknesses and 
                  diameters from extra light through to medium wall and 25NB through to 300NB.
                </p>
                <p>
                  All Orrcon Steel ORRFIRE® products offered to the fire protection market have ActivFire® Scheme approval and 
                  are listed under the register of fire protection equipment. 
                  A variety of surface finishes are available for use in the fire protection industry. 
                  All sprinkler and hydrant pipe products are designed to comply with AS 4118.2.1 and AS 2419.
                </p>
                <p>
                  Orrcon Steel is proud to be a gold member of the Fire Protection Association Australia (FPAA). 
                  We work closely with the FPAA to stay up to date with all aspects of fire safety and fire protection equipment.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/orrfire-firepipe">
                    <h2 className="title">FIREPIPE</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/orrfire-firepipe/activfire-scheme" className="mb-2">
                        Activfire Scheme
                      </Link>
                      <p>ACTIVFIRE® Scheme is your assurance that our products have met the stringent requirements of CSIRO's accreditation process.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/orrfire-firepipe/firepipe_coatings" className="mb-2">
                        Firepipe Coatings
                      </Link>
                      <p>SMARTCOTE® Firepipe Coating (painted in Venetian Red).</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/orrfire-firepipe/pressure-drops-flow-rates" className="mb-2">
                        Pressure Drops & Flow Rates
                      </Link>
                      <p>Orrcon Steel have created full specification tables to assist pipe fabrication.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default FirePipePage

export const Head = () => <title>OrrFire FirePipe</title>
